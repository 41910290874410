import './style.css'
import * as ThreeJS from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { AdditiveBlending } from 'three'

const textureLoader = new ThreeJS.TextureLoader()
const shape = textureLoader.load('/particles/star.png')
const canvas = document.querySelector('canvas.galaxy')
const scene = new ThreeJS.Scene()
const parameters = {}

parameters.count = 70000
parameters.size = 0.001
parameters.radius = 8
parameters.branches = 10
parameters.spin = 5
parameters.randomness = 0.5
parameters.randomnessPower = 10
parameters.stars = 9000
parameters.starColor = '#1b3984'
parameters.insideColor = '#ff6030'
parameters.outsideColor = '#1b3984'

let geometry = null
let material = null
let points = null

function generateGalaxy(){

    if(points !== null){
        geometry.dispose()
        material.dispose()
        scene.remove(points)
    }

    geometry = new ThreeJS.BufferGeometry()

    const positions = new Float32Array(parameters.count *4)
    const colors = new Float32Array(parameters.count *3)

    const colorInside = new ThreeJS.Color(parameters.insideColor)
    const colorOutside = new ThreeJS.Color(parameters.outsideColor)

    for(let i=0; i<parameters.count; i++){
        const x = Math.random() * parameters.radius
        const branchAngle = (i % parameters.branches) / parameters.branches * 2 * Math.PI
        const spinAngle = x * parameters.spin

        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random()<0.5 ? 1: -1) 
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random()<0.5 ? 1: -1) 
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random()<0.5 ? 1: -1)

        positions[i*3] = Math.sin(branchAngle + spinAngle) * x + randomX
        positions[i*3 + 1] = randomY
        positions[i*3 + 2] = Math.cos(branchAngle + spinAngle) * x + randomZ

        const mixedColor = colorInside.clone()
        mixedColor.lerp(colorOutside, x / parameters.radius)

        colors[i*3 + 0] = mixedColor.r
        colors[i*3 + 1] = mixedColor.g
        colors[i*3 + 2] = mixedColor.b
    }

    geometry.setAttribute('position', new ThreeJS.BufferAttribute(positions, 3))
    geometry.setAttribute('color', new ThreeJS.BufferAttribute(colors, 3))

    material = new ThreeJS.PointsMaterial({
        color: 'white',
        size: parameters.size,
        depthWrite: false,
        sizeAttenuation: true,
        blending: AdditiveBlending,
        vertexColors: true,
        transparent: true,
        alphaMap: shape
    })

    points = new ThreeJS.Points(geometry, material)
    scene.add(points)


}

generateGalaxy()

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

const camera = new ThreeJS.PerspectiveCamera(95, sizes.width / sizes.height, 0.5, 100)
camera.position.x = 6
camera.position.y = 3
camera.position.z = 7
scene.add(camera)

const controls = new OrbitControls(camera, canvas)
controls.enabled = false;

const renderer = new ThreeJS.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

const clock = new ThreeJS.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    points.rotation.y = elapsedTime*0.05
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
}

tick()